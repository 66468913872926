<template>
  <div>
    <b-container>
      <b-col
        class="m-2 p-2"
        md="12"
      >
        <h1 class="text-center">
          <feather-icon
            icon="ToolIcon"
            size="20"
          />
          Payment Search Access Settings

        </h1>
      </b-col>
      <b-row>

        <b-col md="6">

          <b-card
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            title="Available modules"
            sub-title="Select a module to give access to the payment search"
          >

            <b-overlay
              :show="showLoading"
              variant="info"
              :opacity="0.50"
              spinner-variant="info"
              spinner-type="grow"
              spinner-small
              rounded="sm"
              style="max-height: 500px;"
            >
              <b-list-group class="scrollable-list">
                <b-list-group-item
                  v-for="(module, key) in permissionsData"
                  :key="key"
                  :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                  class="d-flex justify-content-between"
                  @click="toggleModulePermission(module)"
                >
                  <h4>{{ module.name }}</h4>
                  <b-form-checkbox
                    v-model="module.permit_status"
                    :checked="module.permit_status"
                    switch
                    value="1"
                    unchecked-value="0"
                    @change="toggleModulePermission(module)"
                  />
                <!-- @click.stop -->
                </b-list-group-item>
              </b-list-group>
            </b-overlay>
            <b-row class="d-flex justify-content-between mt-2">

              <b-col>
                <b-form-group
                  id="input-group-3"
                  label-for="input-3"
                >

                  <b-form-select
                    v-model="selected"
                    :options="options"
                    @change="onSelectChangeActions"
                  />

                </b-form-group>
              </b-col>
              <b-col class="text-right">
                <b-button
                  href="#"
                  variant="success"
                  @click="saveChanges"
                >SAVE CHANGES
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">

          <div>
            <b-card
              title="Modules with access"
              sub-title="Current modules with access to payment search"
            >
              <div
                class="d-flex flex-row flex-wrap rounded p-2"
                :class="isDarkSkin ? 'my-bg-info-dark' : 'my-bg-info-light'"
              >
                <!-- Loop through data and display elements horizontally -->

                <div
                  v-if="permitAllowed.length === 0"
                  class="text-center"
                >
                  <h5
                    class="text-warning"
                  >
                    Currently, there are no modules with access to the payment search.
                  </h5>
                </div>
                <div
                  v-for="(data, key) in permitAllowed"
                  v-else

                  :key="key"
                >
                  <h3
                    :class="isDarkSkin ? 'color-dark' :''"
                  >
                    <b-badge
                      class="m-1"
                      :class="isDarkSkin ? 'my-badge-dark' : 'my-badge-light'"
                      variant="info"
                      pill
                    >
                      {{ data.name }}
                    </b-badge>
                  </h3>
                </div>
              </div>
            </b-card>
          </div>

        </b-col>
      </b-row>
      <!-- sin acceso {{ unselectItems }}
      <hr>
      con acceso {{ selectedItems }} -->
    </b-container>
  </div>
</template>

<script>

import ModuleAccessConfigService from '@/views/management/views/settings/views/module_access_config/service/index';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      items: [],
      selectedItems: [],
      unselectItems: [],
      permissionsData: [],
      permitAllowed: [],
      options: [
        {
          value: null,
          text: 'Select an action',
          disabled: true,
        },
        {
          value: 1,
          text: 'Mark default modules',
        },
        {
          value: 2,
          text: 'Reset changes',
        },
        {
          value: 3,
          text: 'Select all',
        },
      ],

      selected: null,

      showLoading: true,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  created() {
    this.getAvalibableModules();
  },

  methods: {

    // principal functions

    async saveChanges() {
      const isAnyCheckboxChecked = this.permissionsData.some(module => module.permit_status === 1);
      if (!isAnyCheckboxChecked) {
        this.showWarningSwal('Please select at least one module to give access.');
        return; // Abort saving changes
      }

      const confirm = await this.showConfirmSwal('IMPORTANT', 'Are you sure you want to perform this action?');
      if (confirm.value) {
        this.addPreloader();
        try {
          const params = {
            user_id: this.currentUser.user_id,
            allow_access: JSON.parse(JSON.stringify(this.permissionsData)),
          };


          const data = await ModuleAccessConfigService.postAccessModuleStatus(params);
          if (data.data.status) {
            this.showSuccessSwal(null, 'Changes save successfully');
            this.getAvalibableModules();
          }

          this.removePreloader();
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
          console.log(error);
        }
      }
    },

    async onSelectChangeActions() {
      const confirm = await this.showConfirmSwal('IMPORTANT', 'Are you sure you want to perform this action?');
      if (confirm.value) {
        switch (this.selected) {
          case 1:
            this.defaultModules();
            break;
          case 2:
            this.getAvalibableModules();
            break;
          case 3:
            this.selectAllModules();
            break;
          default:
            break;
        }
        this.selected = null;
      }
      this.selected = null;
    },

    async getAvalibableModules() {
      const data = await ModuleAccessConfigService.getModuleAvalibable();
      this.items = data.data;

      this.permissionsData = data.data.map(item => ({
        id: item.id,
        module_id: item.module_id,
        name: item.name,
        permit_status: item.permit_status === null || item.permit_status === 0 ? 0 : 1,
      }));

      this.permitAllowed = data.data.filter(item => item.permit_status === 1);
      this.showLoading = false;
    },

    selectAllModules() {
      // Set the permit_status of all modules to 1 (checked)
      this.permissionsData.forEach(module => {
        module.permit_status = 1;
      });
    },

    defaultModules() {
      // Define an array of module_id values to mark as "permit_status = 1"
      const moduleIdsToMark = [2, 3, 5, 7, 8, 18, 21];

      // Update "permit_status" for specified module_id values using array iteration
      this.permissionsData.forEach(module => {
        module.permit_status = moduleIdsToMark.includes(module.module_id) ? 1 : 0;
      });
    },

    toggleModulePermission(module) {
      module.permit_status = module.permit_status === 1 ? 0 : 1;
    },
  },
};

</script>

<style scoped>
.scrollable-list {
  max-height: 500px;
  overflow-y: auto;
}

.my-bg-info-dark{
 opacity: 0.9;
 border: 2px solid #99e8a4;
 box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-bg-info-light{
 opacity: 0.9;
 border: 2px solid #00D25B;
 box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-badge-dark{
  background-color: #bda0e8;
  padding: 0.7rem;
  font-weight: bold !important;
  color: #1e1e1e !important;
  box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-badge-light{
  background-color: #8357D3;
  padding: 0.7rem;
  font-weight: bold !important;
  box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.bg-dark{
  background-color: #282828 !important;
  transition: 0.3s;
}

.bg-dark:hover{
  background-color: #1e1e1e !important;
}

.bg-light{
  background-color: #f7f3ff !important;
  transition: 0.3s;
}

.bg-light:hover{
  background-color: #f0e9ff !important;
}

</style>
