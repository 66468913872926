import { amgApi } from "@/service/axios"

class ModuleAccessConfigServices {
  async getModuleAvalibable(params) {
    try {
      const data = await amgApi.post(
        "management/settings/get-avalibable-modules",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  
  // //get-permit-status-modules

  // async getPermitStatusModules(params) {
  //   try {
  //     const data = await amgApi.post(
  //       "management/settings/get-permit-status-modules",
  //       params
  //     )
  //     return data
  //   } catch (error) {
  //     console.log(error)
  //     throw error
  //   }
  // }

  async postAccessModuleStatus(params) {
    try {
      const data = await amgApi.post(
        "management/settings/post-access-config",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

}

export default new ModuleAccessConfigServices()
